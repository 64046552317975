import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"

const PrevArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 left-3 lg:left-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-left fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const NextArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 right-3 lg:right-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-right fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const DoorTypeAndStyleSelector = ({ setForm, formData }) => {
  const data = useStaticQuery(graphql`
    query DoorTypeAndStyleQuery {
      allPrismicInternalDoor {
        edges {
          node {
            data {
              title {
                text
              }
              woods_and_finishes {
                door_image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
      allPrismicFrontDoor {
        edges {
          node {
            data {
              title {
                text
              }
              woods_and_finishes {
                door_image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
      allPrismicGarageDoor {
        edges {
          node {
            data {
              title {
                text
              }
              woods_and_finishes {
                door_image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const handleClick = (value, doorType) => {
    if (!formData.door_types_and_styles[doorType]?.join(',')?.split(',')?.includes(value)) {
      setForm(doorData => ({
        ...doorData,
        door_types_and_styles: {
          ...doorData.door_types_and_styles,
          [doorType]: [...doorData.door_types_and_styles[doorType], value],
        },
      }))
    } else {
      setForm(doorData => ({
        ...doorData,
        door_types_and_styles: {
          ...doorData.door_types_and_styles,
          [doorType]: doorData.door_types_and_styles[doorType].filter(
            item => item !== value
          ),
        },
      }))
    }
  }

  const settings = {
    className: "w-full px-6 lg:px-28 pt-8 pb-4",
    slidesToShow: 5,
    speed: 500,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    focusOnSelect: false,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <>
      <div className="w-full mb-6">
        <div className="px-6 lg:px-28">
          <h3 className="uppercase tracking-widest text-black text-xs font-bold">
            Internal Doors
          </h3>
        </div>
        <Slider {...settings}>
          {data.allPrismicInternalDoor.edges
            .filter(
              item =>
                item.node.data.woods_and_finishes[0].door_image.fluid !== null
            )
            .map((item, index) => {
              return (
                <div className="px-4 outline-none" key={index}>
                  <div
                    className="cursor-pointer focus:outline-none outline-none"
                    onClick={() =>
                      handleClick(item.node.data.title.text, "internal")
                    }
                    onKeyDown={() =>
                      handleClick(item.node.data.title.text, "internal")
                    }
                    role="button"
                    tabIndex={index}
                  >
                    <div className="relative">
                      {formData.door_types_and_styles?.internal?.join(',')?.split(',')?.includes(item.node.data.title.text) && (
                        <i
                          className="fas fa-check-circle text-white absolute left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                          style={{ top: "45%" }}
                        ></i>
                      )}
                      <img
                        alt={item.node.data.title.text}
                        src={
                          item.node.data.woods_and_finishes[0].door_image.fluid
                            .src
                        }
                      />
                    </div>
                    <p className="text-xs text-center">
                      {item.node.data.title.text}
                    </p>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
      <div className="w-full mb-6">
        <div className="px-6 lg:px-28">
          <h3 className="uppercase tracking-widest text-black text-xs font-bold">
            Front Doors
          </h3>
        </div>
        <Slider {...settings}>
          {data.allPrismicFrontDoor.edges
            .filter(
              item =>
                item.node.data.woods_and_finishes[0].door_image.fluid !== null
            )
            .map((item, index) => {
              return (
                <div className="px-4 outline-none" key={index}>
                  <div
                    className="cursor-pointer focus:outline-none outline-none"
                    onClick={() =>
                      handleClick(item.node.data.title.text, "front")
                    }
                    onKeyDown={() =>
                      handleClick(item.node.data.title.text, "front")
                    }
                    role="button"
                    tabIndex={index}
                  >
                    <div className="relative">
                      {formData.door_types_and_styles?.front?.join(',')?.split(',')?.includes(item.node.data.title.text) && (
                        <i
                          className="fas fa-check-circle text-white absolute left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                          style={{ top: "45%" }}
                        ></i>
                      )}
                      <img
                        alt={item.node.data.title.text}
                        src={
                          item.node.data.woods_and_finishes[0].door_image.fluid
                            .src
                        }
                      />
                    </div>
                    <p className="text-xs text-center">
                      {item.node.data.title.text}
                    </p>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
      <div className="w-full mb-6">
        <div className="px-6 lg:px-28">
          <h3 className="uppercase tracking-widest text-black text-xs font-bold">
            Garage Doors
          </h3>
        </div>
        <Slider {...settings}>
          {data.allPrismicGarageDoor.edges
            .filter(
              item =>
                item.node.data.woods_and_finishes[0].door_image.fluid !== null
            )
            .map((item, index) => {
              return (
                <div className="px-4 outline-none" key={index}>
                  <div
                    className="cursor-pointer focus:outline-none outline-none"
                    onClick={() =>
                      handleClick(item.node.data.title.text, "garage")
                    }
                    onKeyDown={() =>
                      handleClick(item.node.data.title.text, "garage")
                    }
                    role="button"
                    tabIndex={index}
                  >
                    <div className="relative">
                      {formData.door_types_and_styles?.garage?.join(',')?.split(',')?.includes(item.node.data.title.text) && (
                        <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                      )}
                      <img
                        alt={item.node.data.title.text}
                        src={
                          item.node.data.woods_and_finishes[0].door_image.fluid
                            .src
                        }
                      />
                    </div>
                    <p className="text-xs text-center">
                      {item.node.data.title.text}
                    </p>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    </>
  )
}

const DoorTypeAndStyle = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("");

  const handleNext = () => {
    if (
      formData.door_types_and_styles.internal.length === 0 &&
      formData.door_types_and_styles.front.length === 0 &&
      formData.door_types_and_styles.garage.length === 0
    ) {
      setErrors("Please select at least one door type and style.");
    } else {
      next()
    }
  }

  return (
    <Fade className="min-h-0">
      <div className="px-6 lg:px-28 min-h-0">
        <h1 className="font-display text-gold rfs:text-6xl">
          Door Type &amp; Style
        </h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-8">
        <div className="px-6 lg:px-28 mb-8">
          <label
            className="text-uppercase tracking-widest text-black text-xs font-bold block sm:leading-loose leading-tight sm:mb-0 mb-1"
            htmlFor="door_types_and_styles"
          >
            WHICH DOOR(S) ARE YOU INTERESTED IN?{" "}
            <span className="text-gold">*</span>
          </label>
          <p className="text-xs italic text-black mb-5">
            You can select more than one door type and style if you wish.
          </p>
        </div>
        <DoorTypeAndStyleSelector {...{ setForm, formData }} />
      </div>
      <div className="px-6 lg:px-28 flex flex-row justify-between w-full bg-offwhite py-[11px] lg:py-0 min-h-0 shadow-[0px_-4px_5px_0px_rgba(0,0,0,0.1)] lg:shadow-none fixed lg:static bottom-0 left-0 z-50">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button">
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>
    </Fade>
  )
}

export default DoorTypeAndStyle
